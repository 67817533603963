<template>
  <div class="njgl">
    <!-- 详情弹窗 -->
    <Detail v-if="isDetail" @closeDialog="closeDialog" ref="Detail"> </Detail>
    <!-- 主页内容 -->
    <div class="form-list">
      <div class="table-search">
        <h3>农机配置</h3>
        <!-- 根据条件搜索 -->
        <el-form style="margin-left: 20px;" :model="searchForm" label-position="right" label-width="80px" inline
          ref="form">
          <el-form-item label="">
            <el-input style="width: 250px" v-model="searchForm.companyName" placeholder="请输入主体名称">
            </el-input>
          </el-form-item>
          <div>
            <el-button class="query-btn" type="primary" @click="search">查询</el-button>
          </div>
        </el-form>
        <!-- 表格 -->
        <div class="table-content">
          <table-page :pagination="false" :data="tableData" page :columns="columns" :currentPage="query.pnum"
            :pageSize="query.psize" :total="total" :loading="loading" :border="true">
            <template slot="operation" slot-scope="scope">
              <el-button type="text" size="small" @click="detailsClick(scope.row.companyAuthId)">查看</el-button>
            </template>
          </table-page>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { selectAgriculturalManager } from '@/api/njgl'
  import Detail from './components/details.vue'
  import TablePage from "@/components/table/table-page";
  import { njglColumn } from '../tableComlumns.js'
  export default {
    name: 'NowIndex',

    data() {
      return {
        searchForm: {
          companyName: ''
        },
        query: {
          current: 1,
          size: 10,
        },
        tableData: [

        ],
        columns: njglColumn,
        total: 0,
        loading: false,
        isDetail: false,
      };
    },
    components: {
      TablePage,
      Detail,
    },
    mounted() {
      this.selectAgriculturalManager()
    },

    methods: {
      // 搜索
      search() {
        this.selectAgriculturalManager(this.searchForm.companyName)
      },
      // 关闭弹框
      closeDialog() {
        this.isDetail = false;
        this.selectAgriculturalManager()
      },
      // 打开查看弹窗
      detailsClick(companyAuthId) {
        this.isDetail = true;
        this.$nextTick(() => {
          this.$refs.Detail.init(companyAuthId);
        });
      },
      //获取列表
      async selectAgriculturalManager(companyName) {
        let params = {
          ...this.query,
          companyName: companyName
        }
        let res = await selectAgriculturalManager(params)
        if (res.code == 0) {
          this.tableData = res.data.records
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            // duration: 2000,
          })
        }
      },


    },
  };
</script>

<style lang="less" scoped>
  /deep/.el-form--inline {
    display: flex;
    flex-wrap: wrap;
  }

  /deep/.el-form-item {
    width: 25% !important;

    .el-input {
      width: 200px;
    }

    .el-form-item__label {
      width: 100px !important;
    }

    .el-form-item__content {
      width: 200px;
    }

    .el-date-editor {
      width: 200px;
    }
  }

  .form-list {
    margin-left: 10px;

    .table-search {
      h3 {
        margin-left: 20px;
      }
    }
  }

  .njgl {
    width: 100%;
    height: 100%;
  }
</style>
